import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <span className="s1">
  Dashbot, the leading conversational analytics platform, entered into a
  strategic partnership with Conversation.one, a code-free voice and chatbot
  building platform today. The partnership will improve how bot builders track
  performance and iterate their bots.
    </span>
    <span className="s1">
  The partnership between Dashbot and Conversation.One is timely given the
  growth of chatbots, and more significantly, expansion of voice interfaces.
  Amazon stated in its third quarter earnings that they “surpassed 25,000
  skills” and more notably, they’ve recently integrated Alexa into major
  third-party hardware makers including smart home companies (Belkin and Nest),
  automotive companies (Ford), and home security companies (August Smart Lock).
  Amazon has also penetrated commonly used services including Uber, Google
  Calendar, and Spotify. The conversational interface space continues to rapidly
  grow, with Dashbot recently processing its 22 billionth message since its
  founding in 2016.
    </span>
    <span className="s1">
  The partnership includes the option for bot builders to seamlessly integrate
  Dashbot analytics into any conversational interface built with
  Conversation.one. Whether it is a chatbot or a voice application, creators
  will have access to all of their analytics on Dashbot for all conversational
  products. This integration provides a complete suite of Dashbot analytics to
  Conversation.one customers for free. For additional advanced analytics,
  customers can choose upgrade to the premium or enterprise editions of Dashbot.{" "}
    </span>
    <span className="s1">
  Dennis Yang, co-founder and Chief Product Officer at Dashbot, shares his
  support for the new partnership: “Platforms like Conversation.one make it easy
  to build compelling conversational interfaces, and with this simple
  integration with Dashbot analytics, customers will have a full suite of tools
  to build, optimize, learn and iterate on their applications. I’m really
  excited to see what our customers create.”
    </span>
    <span className="s1">
  Rachel Batish, co-founder and Chief Revenue Officer at Conversation.one,
  shares her thoughts on the integration: “The integration with Dashbot is only
  natural for our clients. With millions of voice and chat interactions
  streaming on multiple devices, including Amazon Alexa, Google Home, FB
  Messenger bot, Phone Assistants and Texting interfaces, our clients can now
  track their performance and improve their bots based on real-time analytics. ”
    </span>
    <span className="s1">
  For more information on pricing, documentation, and details on how to get
  started with Conversation.one, please see
  [here](https://docs.conversation.one/docs/dashboard/how-to-use-dashbot-analytics-with-conversation-one/).{" "}
    </span>
    <h3><span className="s2">{`About `}<a parentName="h3" {...{
          "href": "https://conversation.one/"
        }}><span className="s3">{`Conversation.one`}</span></a></span></h3>
    <p><em parentName="p"><span className="s1">{`Build-once-deploy-anywhere conversational apps development platform that helps business to develop Alexa skills, Google Home actions, Chat and Voice bots, as well as Phone Assistant and Texting applications in minutes. `}</span></em></p>
    <p><em parentName="p"><span className="s1">{`Conversation.one leverages machine learning to constantly improve conversational dictionaries and build an enriched, fluent and natural correspondence using any device whilst maximizing the user’s communication.`}</span></em></p>
    <h3><span className="s2">{`About `}<a parentName="h3" {...{
          "href": "https://www.dashbot.io/"
        }}><span className="s3">{`Dashbot`}</span></a></span></h3>
    <p><em parentName="p"><span className="s1">{`Dashbot is a conversational analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools. In addition to traditional analytics like engagement and retention, we provide bot and voice specific metrics including sentiment analysis, conversational analytics, and full chat session transcripts. We also have tools to take action on the data, like our live-person takeover, broadcast messaging, and goal funnels.`}</span></em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      